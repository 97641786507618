import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Dummy from 'dummyjs';

import imgFamilyXX from './img/maschinen/cino-family.png';
import imgKoroPrimeMax from './img/maschinen/koro-prime-max.png';
import imgOptivend from './img/maschinen/optivend-s-ng.png';
import imgSpaqa from './img/maschinen/spaqa-iq.png';
import imgSpaqaPower from './img/maschinen/spaqa-ps-60.png';
import imgLaRhea from './img/maschinen/larhea-business-line.png';
import imgKrea from './img/maschinen/krea-touch-2d.png';
import imgSchaererCoffeeClub from './img/maschinen/schaerer_coffeeclub.png';
import imgSchaererCoffeeSoul from './img/maschinen/schaerer-coffee-soul.png';
import imgSchaererCoffeeArt from './img/maschinen/schaerer-coffee-art-plus.png';
import imgOptiBeanTouch from './img/maschinen/optibean-touch.png';
import imgKalea from './img/maschinen/kalea-plus.png';
import imgAnimo from './img/maschinen/animo-combi-line.png';
import imgMT100 from './img/maschinen/animo-mt-100.png';
// import imgSiamonie from './img/maschinen/siamonie_smart_ocs.png';
import imgSiamonie from './img/maschinen/siamonie_smart_ocs_schwarz.png';
import imgSanRemo from './img/maschinen/sanremo-f18.png';


export const machines_json = 
[
	// 0: Family XX - ganze Bohnen
	{
		ID: 0,
		title: 		"Family XX",
		size: 		['s'],
		products: 	['30212', '30211', '22023', '22035', '30209'],
		img: 		imgFamilyXX,
		body: 		<p>Für ganze Bohnen.<br/>Der kompakte Alleskönner für den perfekten Kaffee-Genuss!</p>,
		leasing: 	<p>LEASINGRATE <span className="price">ab 49,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span><br/><span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span></p>,
		cups: 		<p>ca. 1 - 50<br/>Tassen / Tag</p>,
		people: 	<p>für ca. 1 - 20<br/>Personen</p>,
		extra: 		<p>8<br/> Wahlmöglichkeiten</p>,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>großer Bohnenbehälter</li>
										<li>Behälter für Milchtopping</li>
										<li>Behälter für Kakao</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>Münzschaltgerät</li>
										<li>Unterschrank mit Becherspender und Schublade</li>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Wahlmöglichkeiten</h3>
									<ul className="points">
										<li>Latte Macchiato</li>
										<li>Cappucino</li>
										<li>MoccaCino</li>
										<li>Kaffee schwarz</li>
										<li>Kaffee weiß</li>
										<li>Espresso Macchiato</li>
										<li>Espresso</li>
										<li>Choco</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Breite:</span>300 mm</li>
										<li><span>Höhe:</span>575 mm</li>
										<li><span>Tiefe:</span>500 mm</li>
										<li><span>Ausgabehöhe:</span>140 mm</li>
									</ul>
									<a href="./pdf/dorado-premium-10-compact.pdf" className="pdf-link">Prospekt ansehen / download</a>
								</div>
							</Col>
						</Row>
					),
		info_link: 	''
	},
	
	// 1: Family XX - löslicher Bohnenkaffee
	{
		ID: 1,
		title: 		"Family XX",
		size: 		['s'],
		products: 	['10001', '10003', '22023', '10023', '22035'],
		img: 		imgFamilyXX,
		body: 		<p>Für löslichen Bohnenkaffee.<br/>Der kompakte Alleskönner für den perfekten Kaffee-Genuss!</p>,
		leasing: 	<p>LEASINGRATE <span className="price">ab 35,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span><br/><span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span></p>,
		cups: 		<p>ca. 1 - 50<br/>Tassen / Tag</p>,
		people: 	<p>für ca. 1 - 20<br/>Personen</p>,
		extra: 		<p>8<br/> Wahlmöglichkeiten</p>,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>großer Kaffeebehälter</li>
										<li>Behälter für Milchtopping</li>
										<li>Behälter für Kakao</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>Münzschaltgerät</li>
										<li>Unterschrank mit Becherspender und Schublade</li>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Wahlmöglichkeiten</h3>
									<ul className="points">
										<li>Latte Macchiato</li>
										<li>Cappucino</li>
										<li>MoccaCino</li>
										<li>Kaffee schwarz</li>
										<li>Kaffee weiß</li>
										<li>Espresso Macchiato</li>
										<li>Espresso</li>
										<li>Choco</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Breite:</span>300 mm</li>
										<li><span>Höhe:</span>575 mm</li>
										<li><span>Tiefe:</span>500 mm</li>
										<li><span>Ausgabehöhe:</span>140 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 2: Optivend 32s NG
	{
		ID: 2,
		title: 		"Optivend 32s NG",
		size: 		['s'],
		products: 	['10001', '10003', '22023', '22035', '10023'],
		img: 		imgOptivend,
		body: 		<p>Innen leistungsstark und außen schön! <br/>Für löslichen Bohnenkaffee, frisch und heiß - rund um die Uhr.</p>,
		leasing: 	<p>LEASINGRATE <span className="price">ab 39,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span><br/><span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span></p>,
		cups: 		<p>ca. 1 - 50<br/>Tassen / Tag</p>,
		people: 	<p>für ca. 1 - 20<br/>Personen</p>,
		extra: 		<p>7<br/> Wahlmöglichkeiten</p>,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>3 Behälter für Kaffee, Milchtopping und Kakao</li>
										<li>Heißwasserausgabe</li>
										<li>Festwasseranschluss</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>Münzprüfer</li>
										<li>Unterschrank</li>
										<li>Wasserfilter</li>
										<li>Tassenwärmer</li>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Wahlmöglichkeiten</h3>
									<ul className="points">
										<li>Kaffee Creme</li>
										<li>Espresso</li>
										<li>Cappuccino</li>
										<li>Latte Macchiatto</li>
										<li>Choco</li>
										<li>Kaffee</li>
										<li>Schokolade</li>
										<li>Heißwasser</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Breite:</span>248 mm</li>
										<li><span>Höhe:</span>501 mm</li>
										<li><span>Tiefe:</span>569 mm</li>
										<li><span>Ausgabehöhe:</span>118 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 3: KORO Prime Max
	{
		ID: 3,
		title: 		"KORO Prime Max",
		size: 		['s'],
		products: 	['30212', '30211', '22023', '22035', '30209'],
		img: 		imgKoroPrimeMax,
		body: 		<p>Für ganze Bohnen.<br/>Kompakt, stark und vielfältig.</p>,
		leasing: 	<p>LEASINGRATE <span className="price">ab 61,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span><br/><span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span></p>,
		cups: 		<p>ca. 1 - 50<br/>Tassen / Tag</p>,
		people: 	<p>für ca. 1 - 20<br/>Personen</p>,
		extra: 		<p>8<br/> Wahlmöglichkeiten</p>,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>3 Behälter für Ganze Bohnenkaffee, Milchtopping, Automatenkakao</li>
										<li>Festwasseranschluss oder Wassertank</li>
										<li>Option für entkoffeinierten Kaffee</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>Zahlungsmodul</li>
										<li>Kühlschrank mit Tassenwärmer</li>
										<li>Unterschrank</li>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Wahlmöglichkeiten</h3>
									<ul className="points">
										<li>Kaffee Creme</li>
										<li>Espresso</li>
										<li>Cappuccino</li>
										<li>Latte Macchiatto</li>
										<li>Latte</li>
										<li>Espresso</li>
										<li>Kaffee</li>
										<li>Choco</li>
										<li>Heißwasser</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Breite:</span>330 mm</li>
										<li><span>Höhe:</span>610 mm</li>
										<li><span>Tiefe:</span>530 mm</li>
										<li><span>Ausgabehöhe:</span>140 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 5: laRhea Grande Business Line - ganze Bohnen
	{
		ID: 5,
		title: 		"laRhea iC BUSINESS LINE",
		size: 		['m'],
		products: 	['30212', '30211', '22023', '22035', '30209'],
		img: 		imgLaRhea,
		body: 		<p>Für ganze Bohnen. <br/>Hybridtechnologie mit einem extra Behälter für individuelle Produkte, wie Fairtrade Kaffee oder laktosefreie Milchprodukte.</p>,
		leasing: 	<p>LEASINGRATE <span className="price">ab 75,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span><br/><span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span></p>,
		cups: 		<p>1 - 100<br/>Tassen / Tag</p>,
		people: 	<p>für ca. 20 - 50<br/>Personen</p>,
		extra: 		<p>12<br/> Wahlmöglichkeiten</p>,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>1 Mühle mit Bohnenbehälter und</li>
										<li>2 Behälter für Milchtopping und Kakao</li>
										<li>und individuelle Produkte</li>
										<li>Festwasseranschluss</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>Münzgerät</li>
										<li>Becherspender</li>
										<li>Unterschrank</li>
										<li>Bezahlmodul</li> 
										<li>Wassertank-Modul</li>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Wahlmöglichkeiten</h3>
									<ul className="points">
										<li>MoccaCino</li>
										<li>Cappucino</li>
										<li>Cappucino Extra</li>
										<li>Espresso Macchiato</li>
										<li>Espresso</li>
										<li>Cafe au lait</li>
										<li>Latte Macchiato</li>
										<li>Kaffee schwarz</li>
										<li>Kaffee weiß</li>
										<li>Choco</li>
										<li>Choco Creme</li>
										<li>Teewasser</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Höhe:</span>560 mm</li>
										<li><span>Breite:</span>318 mm</li>
										<li><span>Tiefe:</span>559 mm</li>
										<li><span>Ausgabehöhe:</span>145 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 6: laRhea Grande Business Line - löslicher Kaffee
	{
		ID: 6,
		title: 		"laRhea iC BUSINESS LINE",
		size: 		['m'],
		products: 	['10001', '10003', '10026', '22023', '22035'],
		img: 		imgLaRhea,
		body: 		<p>Für löslichen Bohnenkaffee. <br/>Hybridtechnologie mit einem extra Behälter für individuelle Produkte, wie Fairtrade Kaffee oder laktosefreie Milchprodukte.</p>,
		leasing: 	<p>LEASINGRATE <span className="price">ab 48,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span><br/><span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span></p>,
		cups: 		<p>1 - 100<br/>Tassen / Tag</p>,
		people: 	<p>für ca. 20 - 50<br/>Personen</p>,
		extra: 		<p>12<br/> Wahlmöglichkeiten</p>,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>4 Behälter für Kaffee, Milchtopping und Kakao und individuelle Produkte</li>
										<li>Festwasseranschluss</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>Münzgerät</li>
										<li>Becherspender</li>
										<li>Unterschrank</li>
										<li>Bezahlmodul</li>
										<li>Wassertank-Modul</li>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Wahlmöglichkeiten</h3>
									<ul className="points">
										<li>MoccaCino</li>
										<li>Cappucino</li>
										<li>Cappucino Extra</li>
										<li>Espresso Macchiato</li>
										<li>Espresso</li>
										<li>Cafe au lait</li>
										<li>Latte Macchiato</li>
										<li>Kaffee schwarz</li>
										<li>Kaffee weiß</li>
										<li>Choco</li>
										<li>Choco Creme</li>
										<li>Teewasser</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Höhe:</span>560 mm</li>
										<li><span>Breite:</span>318 mm</li>
										<li><span>Tiefe:</span>559 mm</li>
										<li><span>Ausgabehöhe:</span>145 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 7: Krea Touch
	{
		ID: 7,
		title: 		"Krea Touch",
		size: 		['m'],
		products: 	['30212', '30211', '22023', '22035', '30209'],
		img: 		imgKrea,
		body: 		<p>Für ganze Bohnen.<br/>Der Vollautomat mit exquisitem Design, unübertroffener Leistung und sehr geringe Wartungskosten! Perfekt für die Frühstücksversorgung und Bäckereien.</p>,
		leasing: 	<p>LEASINGRATE <span className="price">ab 76,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span><br/><span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span></p>,
		cups: 		<p>1 - 100<br/>Tassen / Tag</p>,
		people: 	<p>für ca. 20 - 50<br/>Personen</p>,
		extra: 		<p>Bis zu 10<br/> Wahlmöglichkeiten</p>,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>7“ HD Touch Screen</li>
										<li>1 Mühle mit Bohnenbehälter, zzgl. 3 Instant Behälter für Kakao, Milchtopping und einer Spezialität oder koffein- freiem Kaffee</li>
										<li>Festwasseranschluss</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>Bezahlsystem</li>
										<li>Unterschrank</li>
										<li>Tassenwärmer</li>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Wahlmöglichkeiten</h3>
									<ul className="points">
										<li>10 Wahlmöglichkeiten, z.B.: </li>
										<li>Kaffee Crema</li>
										<li>Espresso</li>
										<li>Cappuccino</li>
										<li>Latte Macchiato</li>
										<li>Kakao</li>
										<li>Heißwasserausgabe für Tee</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Breite:</span>410 mm</li>
										<li><span>Höhe:</span>750 mm</li>
										<li><span>Tiefe:</span>574 mm</li>
										<li><span>Ausgabehöhe:</span>130 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 9: Schaerer Coffee Club
	{
		ID: 9,
		title: 		"Schaerer Coffee Club",
		size: 		['l'],
		products: 	['30212', '30211', '22023', '22035', '30209'],
		img: 		imgSchaererCoffeeClub,
		body: 		<p>Für ganze Bohnen.<br/>Die Schaerer Coffee Club öffnet die Tür in die Welt der professionellen Kaffeezubereitung. Auf kleinstem Raum und verpackt in schickem Design bringt die alles mit, was Sie für schmackhafte Kaffeespezialitäten benötigen: ausgereifte Technik, einfachste Handhabung, höchste Flexibilität. <br/>Als Zubehör empfehlen wir den Milchkühlschrank.</p>,
		leasing: 	<p>
						LEASINGRATE mit Milchpulversystem <span className="price">ab 83,50 € zzgl.&nbsp;MwSt. /&nbsp;Monat</span> <br/>
						LEASINGRATE mit Frischmilchsystem <span className="price">ab 85,70 € zzgl.&nbsp;MwSt. /&nbsp;Monat</span> <br/>
						LEASINGRATE Milchkühlschrank 3,5l&nbsp;&nbsp; <span className="price">ab 16,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span> <br/>
						<span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span>
					</p>,
		cups: 		<p>1 - 120<br/>Tassen / Tag</p>,
		people: 	<p>bis ca. 100<br/>Personen</p>,
		extra: 		<p>40<br/> Wahlmöglichkeiten</p>,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>7“ Touch Display</li>
										<li>Integrierter Dampf-Tassenwärmer</li>
										<li>Tankversion und Festwasseranschluss</li>
										<li>Frischmilchsystem mit 1 Mühle inkl. Bohnenbehälter + Pulverbehälter für Choco</li>
										<li>Milchpulversystem mit 1 Mühle inkl. Bohnenbehälter</li>
										<li>Pulverbehälter für Choc- & Milchtopping</li>
										<li>Anbindung von Bezahlsystemen via MDB-Schnittstelle</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>2. Mühle inkl. Bohnenbehälter</li>
										<li>Pulverbehälter für Choco- oder Toppingpulver</li>
										<li>Bohnenbehältererweiterung </li>
										<li>Festwasseranschluss</li>
										<li>Powersteam</li>
										<li>Untertheken-Satzdurchwurf</li>
										<li>Mini-Kühlschrank 1 Liter</li>
										<li>Milchkühler 3,5 Liter</li>
										<li>coffeeMYsation-App</li>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Wahlmöglichkeiten</h3>
									<ul className="points">
										<li>Ristretto</li>
										<li>Espresso</li>
										<li>Kaffee / Kaffee Crema </li>
										<li>Kännchen (250 ml)</li>
										<li>Milchkaffee / Schale</li>
										<li>Cappuccino</li>
										<li>Latte Macchiato</li>
										<li>Chociatto</li>
										<li>Heisse Milch</li>
										<li>Heisser Milchschaum</li>
										<li>Heißwasserausgabe für Tee </li>
										<li>Dampf</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Breite:</span>325 mm</li>
										<li><span>Höhe:</span>561 mm</li>
										<li><span>Tiefe:</span>500 mm</li>
										<li><span>Ausgabehöhe:</span>175 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 10: Sielaff Siamonie Smart
	{
		ID: 10,
		title: 		"Sielaff Siamonie Smart",
		img: 		imgSiamonie,
		size: 		['l'],
		products: 	['30212', '30211', '22023', '22035', '30209'],
		body: 		<p>Für ganze Bohnen.<br/>Das kompakte OCS-Gerät von Sielaff vereint elegante Optik, vollendeten Geschmack und technische Perfektion - so bringen Sie genussvolle Abwechslung in Ihren Büroalltag!</p>,
		leasing: 	<p>LEASINGRATE <span className="price">ab 108,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span><br/><span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span></p>,
		cups: 		<p>bis zu ca. 125<br/>Tassen / Tag</p>,
		people: 	<p>für ca. 50-100<br/>Personen</p>,
		extra: 		<p>34<br/> Wahlmöglichkeiten</p>,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>Einfach zu bedienender Touchscreen mit intuitiver Benutzeroberfläche</li>
										<li>Individuell mit Ihrem Firmenlogo, Bild, Video oder Werbung als Bildschirmschoner</li>
										<li>2 Behälter für ganze Bohnen,</li>
										<li>2 Behälter für Milchtopping und Kakao</li>
										<li>Festwasseranschluss</li>
										<li>Horizontal-Brüher</li>
										<li>Geschmack individuell einstellbar</li>
										<li>Doppelter Espressoauslauf</li>
									</ul>
									<h3>Wahlmöglichkeiten</h3>
									<ul>
										<li>34 Wahlmöglichkeiten, z.B. </li>
										<li>Kaffee Crema</li>
										<li>Espresso</li>
										<li>doppelter Espresso</li>
										<li>Cappuccino</li>
										<li>Latte Macchiato</li>
										<li>Kakao</li>
										<li>Ristretto </li>
										<li>Heißwasserausgabe für Tee</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Breite:</span>380 mm</li>
										<li><span>Höhe:</span>545 mm</li>
										<li><span>Tiefe:</span>540 mm</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Optional</h3>
									<ul>
										<li>Zweite Mühle
											<ul>
												<li>Unterschiedliche Bohnensorten mit eigenem Mahlgrad persönlich&nbsp;anpassen</li>
												<li>Mahlgrad präzise ohne Aufwand über Drehkranz einstellbar</li>
											</ul>
										</li>
										<li>Wassertank
											<ul>
												<li>Unabhängig von einem Wasseranschluss</li>
												<li>ca. 3,5 l Inhalt</li>
												<li>Integrierter Wasserfilter im Wassertank</li>
												<li>Füllstandsüberwachung</li>
												<li>Mühelos zu entnehmen und zu befüllen</li>
											</ul>
										</li>
										<li>
											Satzabwurf und Direktablauf
											<ul>
												<li>Unkomplizierte Entsorgung von Restwasser und Kaffeekuchen</li>
											</ul>
										</li>
										<li>Integrierter Münzprüfer und Schlüsselschalter
											<ul>
												<li>Münzprüfer und Schlüsselschalter für Betrieb in der Öffentlichkeit</li>
												<li>Integrierte, absperrbare und geräumige Kasse</li>
												<li>Schlüsselschalter für Freiverkäufe</li>
												<li>Kannenschaltung</li>
											</ul>
										</li>
										<li>Zahlungsmodul 
											<ul>
												<li>Münzschaltgerät kann je nach Bedarf eingebaut werden</li>
												<li>MDB-Anschluss ist vorhanden</li>
												<li>In RAL 9005 Tiefschwarz erhältlich</li>
											</ul>
										</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 11: OptiBean 3 Touch
	{
		ID: 11,
		title: 		"OptiBean 3 Touch",
		size: 		['l'],
		products: 	['30212', '30211', '22023', '22035', '30209'],
		img: 		imgOptiBeanTouch,
		body: 		<p>Für ganze Bohnen.<br/>Die intelligente Espressomaschine mit Touchscreen, intuitiver Benutzeroberfläche und unverwechselbarem Design. Einfach in der Handhabung und leicht zu pflegen.</p>,
		leasing: 	<p>LEASINGRATE <span className="price">ab 100,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span><br/><span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span></p>,
		cups: 		<p>bis zu 250<br/>Tassen / Tag</p>,
		people: 	<p>für ca. 50 - 80<br/>Personen</p>,
		extra: 		<p>24<br/> Wahlmöglichkeiten</p>,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>Bildschirmschoner für Logo, Bilder, Video oder Werbung</li>
										<li>Verfügbar mit Standard oder XL Brüher für große Tassen</li>
										<li>Die LED/OptiLight-Beleuchtung kann individuell angepasst werden.</li>
										<li>1 großer Behälter für ganze Bohnen ca. 1.500 g - 2 Behälter für Milchtopping und Kakao</li>
										<li>Festwasseranschluss</li>
										<li>Keramik-Mahlscheiben</li>
										<li>Geschmak individuell einstellbar</li>
										<li>Doppelter Espressoauslauf</li>
										<li>riesiger Abfallbehälter (ca. 130 Tassen)</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>Individuelle Konfiguration der Seiten- und Maschinenhinterteil </li>
										<li>Große Espresso Brüheinheit mit 9,5 - 13,4 gr./ 150 - 230 ml - Tassenwärmer</li>
										<li>Unterschrank</li>
										<li>weiteres Zubehör auf Anfrage möglich</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Wahlmöglichkeiten</h3>
									<ul className="points">
										<li>24 Wahlmöglichkeiten, z.B.: </li>
										<li>Kaffee Crema</li>
										<li>Espresso</li>
										<li>Cappuccino</li>
										<li>Latte Macchiato</li>
										<li>Kakao</li>
										<li>Heißwasserausgabe für Tee</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Breite:</span>409 mm</li>
										<li><span>Höhe:</span>793 mm</li>
										<li><span>Tiefe:</span>565 mm</li>
										<li><span>Ausgabehöhe:</span>167 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 12: Kalea 2 ES
	{
		ID: 12,
		title: 		"Kalea 2 ES für Frischmilch",
		size: 		['l'],
		products: 	['30212', '30211', '22023', '22035', '30209'],
		img: 		imgKalea,
		body: 		<p>Für ganze Bohnen.<br/>
						KALEA bietet den perfekten italienischen Espresso, hervorragenden Kaffee sowie weitere, mit Frischmilch verfeinerte Heißgetränkespezialitäten. <br/>Perfekt für den Bediener und in der Selbstbedienung. <br/>
						Alternativ auch als Milchpulversystem erhältlich
					</p>,
		leasing: 	<p>
						LEASINGRATE <span className="price">ab 110,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span><br/>
						LEASINGRATE Kühlschrank mit Tassenwärmer <span className="price_long">ab&nbsp;28,-&nbsp;€ zzgl.&nbsp;MwSt.&nbsp;/&nbsp;Monat</span><br/>
						<span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span>
					</p>,
		cups: 		<p>bis zu ca. 200<br/>Tassen / Tag</p>,
		people: 	<p>für ca. 50 - 80<br/>Personen</p>,
		extra: 		<p>24<br/> Wahlmöglichkeiten</p>,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>HD 7“ Touch Screen</li>
										<li>Empfohlen wird der Milchkühlschrank</li>
										<li>2 große Behälter für ganze Bohnen ca. 1.500 g</li>
										<li>1 Behälter für Kakao</li>
										<li>Festwasseranschluss</li>
										<li>Espresso Doppelauslauf</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>mit Tassenwärmer</li>
										<li>Milchkühlschrank mit transparenter Tür</li>
										<li>Unterschrank</li>
										<li>Kaffeeabfall-Entleerungskit für Unterschrank </li>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Wahlmöglichkeiten</h3>
									<ul className="points">
										<li>24 Wahlmöglichkeiten, z.B.: </li>
										<li>Kaffee Crema</li>
										<li>Espresso</li>
										<li>Cappuccino</li>
										<li>Latte Macchiato</li>
										<li>Kakao</li>
										<li>Heißwasserausgabe für Tee</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Breite:</span>368 mm</li>
										<li><span>Höhe:</span>783 mm</li>
										<li><span>Tiefe:</span>586 mm</li>
										<li><span>Ausgabehöhe:</span>140 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 13: Schaerer Coffee Soul
	{
		ID: 13,
		title: 		"Schaerer Coffee Soul",
		size: 		['xl'],
		products: 	['30212', '30211', '22023', '22035', '30209'],
		img: 		imgSchaererCoffeeSoul,
		body: 		<p>Für ganze Bohnen.<br/>Barista inside: die leistungsstarke Schaerer Coffee Soul ist perfekt für die Gastronomie. Sie vereint ein kompaktes, elegantes Design mit einer Vielzahl technischer Raffinessen für den perfekten Kaffee-Genuss.</p>,
		leasing: 	
						<p>
							LEASINGRATE mit Milchpulversystem <span className="price">ab 228,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span> <br/>
							LEASINGRATE mit Frischmilchsystem <span className="price">ab 292,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span> <br/>
							<span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span>
						</p>,
		cups: 		<p>bis zu ca. 250<br/>Tassen / Tag</p>,
		people: 	<p>ab 80<br/>Personen</p>,
		extra: 		<p>100<br/> Wahlmöglichkeiten</p>,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>7“ Touch Display</li>
										<li>Integrierter Dampf-Tassenwärmer</li>
										<li>Tankversion und Festwasseranschluss</li>
										<li>Frischmilchsystem mit 1 Mühle inkl. Bohnenbehälter</li>
										<li>+ Pulverbehälter für Choco</li>
										<li>Milchpulversystem mit 1 Mühle inkl. Bohnenbehälter</li>
										<li>Pulverbehälter für Choc- & Milchpulver</li>
										<li>Anbindung von Bezahlsystemen via MDB-Schnittstelle</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>2. Mühle inkl. Bohnenbehälter</li>
										<li>Pulverbehälter für Choco- oder Toppingpulver </li>
										<li>Milchsystem Best Foam</li>
										<li>Powersteam</li>
										<li>Abschließbare Kühleinheit </li>
										<li>Untertheken-Satzdurchwurf</li>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
									<h3>Systeme</h3>
									<p>
										<strong>Milchpulversystem</strong><br/>
										Schaerer Soul Grundausstattung <br/>
										+ zweiter Bohnenbehälter inkl. Mühle <br/>
										+ Großer Twinbehälter für Choco und Milchtopping 							
									</p>
									<p>
										<strong>Frischmilchsystem und Milchkühlschrank</strong><br/>
										Schaerer Soul Grundausstattung<br/>
										+ zweiter Bohnenbehälter inkl. Mühle<br/>
										+ Großer Chocobehälter<br/>
										+ Milchkühlschrank 10 Liter
									</p>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Wahlmöglichkeiten</h3>
									<ul className="points">
										<li>100 Wahlmöglichkeiten, u.a.: </li>
										<li>Ristretto</li>
										<li>Espresso</li>
										<li>Kaffee / Kaffee Crema </li>
										<li>Kännchen (250 ml)</li>
										<li>Kanne (500 ml)</li>
										<li>Cappuccino</li>
										<li>Americano</li>
										<li>White Americano</li>
										<li>Latte Macchiato</li>
										<li>Heisse Schokolade</li>
										<li>Flat White</li>
										<li>u.v.m.</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Breite:</span>330 mm</li>
										<li><span>Höhe:</span>600 mm</li>
										<li><span>Tiefe:</span>738 mm</li>
										<li><span>Ausgabehöhe:</span>65 - 185 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 14: Schaerer Coffee Art Plus Coffee Shop
	{
		ID: 14,
		title: 		"Schaerer Coffee Art Plus Coffee Shop",
		size: 		['xl'],
		products: 	['30212', '30211', '22023', '22035', '30209'],
		img: 		imgSchaererCoffeeArt,
		body: 		<p>
						Für ganze Bohnen.<br/>
						Die Schaerer Coffee Art Plus bietet Spitzenleistungen auf allen Ebenen: Getränkevielfalt auf höchstem Barista-Niveau, zahlreiche Konfigurationsmöglichkeiten und einfachste Bedienung.
						Zubehör: <br/>
						- Coffee Shop Kaltmilchsystem (Kühleinheit 8l) <br/>
						- Best Foam TM<br/>
						- Autosteam (PEEK Dampfstab)<br/>
						- 2 Mühlen inkl. Bohnenbehälter<br/>
						- Bohnenbehälter in Coffee Shop Optik<br/>
						- Pulversystem für Choco
					</p>,
		leasing: 	<p>LEASINGRATE mit Frischmilchsystem und o.g. Zubehör <span className="price_long">ab 430,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span> <br/><span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span></p>,
		cups: 		<p>1 - 350<br/>Tassen / Tag</p>,
		people: 	<p>ab 80<br/>Personen</p>,
		extra: 		<p>100<br/> Wahlmöglichkeiten</p>,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>7“ Touchscreen-Display</li>
										<li>Two Step Milchsystem</li>
										<li>Manuelles Aufschäumen der Milch per Dampflanze für „Barista Feeling“</li>
										<li>Vollautomatisches Aufschäumen durch zusätzlichem Milchkühler</li>
										<li>2 große Bohnenbehälter und großer Pulverbehälter für Choco</li>
										<li>Einfache Reinigung und Handhabung durch HACCP-Konzept</li>
										<li>Frischmilchsystem mit 2. Mühle inkl. Bohnenbehälter + Pulverbehälter für Choco</li>
										<li>Milchpulversystem mit 2. Mühle inkl. Bohnenbehälter + Twin-Pulverbehälter für Choco & Milchpulver möglich</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Wahlmöglichkeiten</h3>
									<ul className="points">
										<li>100 Wahlmöglichkeiten, u.a.: </li>
										<li>Ristretto</li>
										<li>Espresso</li>
										<li>Kaffee / Kaffee Crema </li>
										<li>Kännchen (250 ml)</li>
										<li>Cappuccino</li>
										<li>Latte Macchiato</li>
										<li>Chociatto</li>
										<li>Heisse Milch</li>
										<li>Heisser Milchschaum</li>
										<li>Heisswasser</li>
										<li>u.v.m.</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Breite:</span>420 mm</li>
										<li><span>Höhe:</span>538 mm</li>
										<li><span>Tiefe:</span>668 mm</li>
										<li><span>Ausgabehöhe:</span>65 - 185 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 15: Sanremo F18
	{
		ID: 15,
		title: 		"Sanremo F18",
		size: 		['xl'],
		products: 	['30212', '30211', '30209'],
		img: 		imgSanRemo,
		body: 		<p>
						Für ganze Bohnen.<br/>
						Die Siebträgermaschine F-18 erlaubt es dem Barista, auf alle wesentlichen Funktionen sofort zuzugreifen. Die Bedienung eingängig und auch die technischen Einstellungen gelingen einfach und unkompliziert. So gelingen mit den idealen Brühparametern professionelle Kaffee-Kreationen auf höchstem Niveau. Als zweigruppige oder dreigruppige Maschine erhältlich.
						<br/>Zubehör: Mühle Sanremo SR 64 OD
					</p>,
		leasing: 	<p>LEASINGRATE mit Zubehör <span className="price">ab 236,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span> <br/>(für zweigruppige Maschine) <br/><span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span></p>,
		cups: 		null,
		people: 	null,
		extra: 		null,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>Energiesparendes System</li>
										<li>Multifunktionsanzeige</li>
										<li>In zwei Farben verfügbar</li>
										<li>Ab zwei Brühgruppen</li>
										<li>2 Dampfhähne</li>
										<li>1 Heißwasserauslauf</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>weitere Mühlen</li>
										<li>Reinigungsmittel</li>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Wahlmöglichkeiten</h3>
									<ul className="points">
										<li>Ristretto</li>
										<li>Espresso</li>
										<li>Cappuccino</li>
										<li>Latte Macchiato</li>
										<li>Kaffee Crema</li>
										<li>Heisse Milch</li>
										<li>U.v.m.</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Breite:</span>1072 mm</li>
										<li><span>Höhe:</span>526 mm</li>
										<li><span>Tiefe:</span>647 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 16: Animo CB 2 x 5 ltr Combiline
	{
		ID: 16,
		title: 		"Animo CB 2 x 5 ltr Combiline",
		size: 		['xl'],
		products: 	['30121', '30217', '30210'],
		img: 		imgAnimo,
		body: 		<p>Für Filterkaffee. <br/>Einfach, schnell und zuverlässig. Mit der Combiline können Sie einen vollen Behälter mit leckerem Filterkaffee innerhalb von 15 Minuten zubereiten. Verfügbar in 5, 10 und 20 Liter-Versionen.</p>,
		leasing: 	<p>LEASINGRATE <span className="price">ab 72,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span> <br/><span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span></p>,
		cups: 		null,
		people: 	null,
		extra: 		null,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>Festwasseranschluss</li>
										<li>Inkl. No-Drip Hahn für Heißwasser</li>
										<li>Stundenleistung ab 30 Liter Kaffee / 33 Liter Heißwasser</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>Verfügbar in 5, 10 und 20 Liter-Versionen. </li>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Abmessungen</h3>
									<ul className="table">
										<li>2 x 5 Liter Version</li>
										<li><span>Breite:</span>815 mm</li>
										<li><span>Höhe:</span>470 mm</li>
										<li><span>Tiefe:</span>700 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 17: Spaqa 4.0 Powerspeed
	{
		ID: 17,
		title: 		"Spaqa 4.0 Powerspeed",
		size: 		['xl'],
		img: 		imgSpaqaPower,
		body: 		<p>Der intelligente Qualitäts-Wasserspender für Sie, Ihre Kunden, Gäste und Mitarbeiter - kos- tensparend und umweltschonend. Der Spaqa iQ bietet gefiltertes gekühltes, ungekühltes und mit CO<sup>2</sup> versetztes Wasser. Die Ausgabe erfolgt auf Wunsch in Becher, Gläser, Karaffen oder Arbeitsplatzflaschen. Konstante Kühlleistung für bis zu 60 Liter pro Stunde.</p>,
		leasing: 	<p>LEASINGRATE <span className="price">ab 63,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span><br/><span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span></p>,
		cups: 		<p>60 l gekühltes<br/>Wasser / Stunde</p>,
		people: 	null,
		extra: 		<p>3<br/> Wahlmöglichkeiten</p>,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Zubehör</h3>
									<ul>
										<li>Einwegkohlensäureflasche</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Wahlmöglichkeiten</h3>
									<ul className="points">
										<li>Tafelwasser mit CO<sup>2</sup>, gekühlt</li>
										<li>Tafelwasser still, gekühlt</li>
										<li>Tafelwasser still, ungekühlt</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Breite:</span>189 mm</li>
										<li><span>Höhe:</span>528 mm</li>
										<li><span>Tiefe:</span>535 mm</li>
										<li><span>Ausgabehöhe:</span>335 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 8: Pumpkanne MT 100
	{
		ID: 8,
		title: 		"Pumpkanne MT 100 Kompakt",
		size: 		['m', 'l'],
		products: 	['30108', '92010', '30130', '30107'],
		img: 		imgMT100,
		body: 		<p>Für vorportionierte Kaffeefilter (Pouch). <br/>Die schnelle, praktische und einfach Lösung für Betrieb und Gastronomie. <br/>Die vorportionierten Kaffeefilter sorgen für eine einfache und gleichbleibende Dosierung pro Pumpkanne ohne Produktverlust durch unsauberes Dosieren von losem Kaffeemehl. Alternativ auch als Rundfiltermaschine erhältlich.</p>,
		leasing: 	<p>Kostenlose Gestellung bei Bezug <br/>eines Kartons Kaffee/Monat (50&nbsp;x&nbsp;60g&nbsp;Pouch).<br/><span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span></p>,
		cups: 		<p>ca. 17<br/>Tassen / Brühung</p>,
		people: 	null,
		extra: 		null,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>Stundenleistung:14 Liter = 122 Tassen</li>
										<li>Stoßvorrat: 2,1 Liter = 17 Tassen</li>
										<li>Brühzeit: ca. 7 Minuten / Kanne</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>Servicestation</li>
										<li>Tassenwärmer</li>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Breite:</span>205 mm</li>
										<li><span>Höhe:</span>595 mm</li>
										<li><span>Tiefe:</span>380 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	},

	// 4: Spaqa iQ
	{
		ID: 4,
		title: 		"Spaqa iQ",
		size: 		['s', 'm', 'l'],		
		img: 		imgSpaqa,
		body: 		<p>Der intelligente Qualitäts-Wasserspender für Sie, Ihre Kunden, Gäste und Mitarbeiter - kostensparend und umweltschonend. Der Spaqa iQ bietet gefiltertes gekühltes, ungekühltes und mit CO<sup>2</sup> versetztes Wasser. Konstante Kühlleistung für bis zu 20l pro Stunde.</p>,
		leasing: 	<p>LEASINGRATE <span className="price">ab 40,- € zzgl.&nbsp;MwSt. /&nbsp;Monat</span><br/><span className="small">Laufzeit flexibel. Kauf auf Anfrage möglich.</span></p>,
		cups: 		<p>ca. 20 l gekühltes<br/>Wasser / Stunde</p>,
		people: 	null,
		extra: 		<p>3<br/> Wahlmöglichkeiten</p>,
		info: 		(
						<Row>
							<Col>
								<div className="info-wrapper">
									<h3>Technik</h3>
									<ul>
										<li>Durchflussleistung 3 Liter / min</li>
										<li>Getränkeausgabe beleuchtet</li>
										<li>Festwasseranschluss</li>
									</ul>
									<h3>Zubehör</h3>
									<ul>
										<li>Einwegkohlensäureflasche</li>
									</ul>
									<h3>Optional</h3>
									<ul>
										<li>Weiteres Zubehör auf Anfrage</li>
									</ul>
								</div>
							</Col>
							<Col>
								<div className="info-wrapper">
									<h3>Wahlmöglichkeiten</h3>
									<ul className="points">
										<li>Tafelwasser mit CO<sup>2</sup>, gekühlt</li>
										<li>Tafelwasser still, gekühlt</li>
										<li>Tafelwasser still, ungekühlt</li>
									</ul>
									<h3>Abmessungen</h3>
									<ul className="table">
										<li><span>Breite:</span>240 mm</li>
										<li><span>Höhe:</span>510 mm</li>
										<li><span>Tiefe:</span>445 mm</li>
										<li><span>Ausgabehöhe:</span>260 mm</li>
									</ul>
								</div>
							</Col>									
						</Row>
					),
		info_link: 	''
	}

]