import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {Container} from 'react-bootstrap';

import logo from './img/logo_comes.svg';
import * as Scroll from 'react-scroll';

export default class Header extends React.Component {

	render() {

		let bg_class = this.props.withBG ? ' withBG' : '';

		return (
			<header className={"Header" + bg_class}>
				<Container>
					<div className="header-bg">
						<section className="title">
							<h2>Der passende</h2>
							<h1>Kaffee-Vollautomat</h1>
							<h2>für dein Team.</h2>
						</section>
					</div>
					<div className="logo_menu_wrapper">
						<Scroll.Link to="home" duration={1000} smooth={true}><img className="logo" src={logo} alt="Comes Kaffeekontor" width='175' height='175' /></Scroll.Link>
						<ul className="menu">
							<li><a href="https://comeskaffeekontor.de/" target="_blank">Über Uns</a></li>
							<li><a href="https://www.shop-comcafe.de/" target="_blank">Shop</a></li>
							<li><Scroll.Link to="kontakt" smooth={true} duration={1000} offset={-200}>Kontakt</Scroll.Link></li>
						</ul>
					</div>					
				</Container>
			</header>
		);
	}
}
