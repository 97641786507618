import React from 'react';

import MainTemplate from './MainTemplate';
import CoffeeMaschine from './CoffeeMaschine';
import * as Scroll from 'react-scroll';

import {machines_json} from './CoffeeMaschinesJSON.js';

class ViewMachines extends React.Component {

	constructor(props)
	{
		super(props);
	}

	getNextLink(){
		return '';
	}

	createMachines(){

		let lightbox_images = [
			[
				{
					src: process.env.PUBLIC_URL + "/img/maschinen/animo-combi-line.png",
					alt: 'Animo Combi'
				},
				{
					src: process.env.PUBLIC_URL + "/img/maschinen/animo-mt-100.png",
					alt: 'Animo MT 100'
				},
				{
					src: process.env.PUBLIC_URL + "/img/maschinen/cino-family.png",
					alt: 'Cino Family'
				},
			],
			[
				{
					src: process.env.PUBLIC_URL + "/img/maschinen/kalea-plus.png",
					alt: 'Kalea Plus'
				},
				{
					src: process.env.PUBLIC_URL + "/img/maschinen/koro-prime-max.png",
					alt: 'Koro Prime Max'
				}
			]
		];

		let machines = [];
		let num_machine = 0;

		for(const machine of machines_json)
		{

			machines.push(
					<CoffeeMaschine
						key={machine.ID}
						ID={machine.ID}
						title={machine.title}
						img={machine.img}
						body={machine.body}
						leasing={machine.leasing}
						cups={machine.cups}
						people={machine.people}
						extra={machine.extra}
						info={machine.info}
						cb_select={this.selectMachine}
						next_active={false}
						next_url={this.getNextLink()}
						lightbox={lightbox_images[num_machine++%2]}
					/>
				);
		}

		return machines;
	}

	render() {

		let machines = this.createMachines();

		return (
			<MainTemplate>
				<Scroll.Element name={"intro"}>
					<div className="intro">
						<h2>Genau richtig</h2>
						<p>Hier findest du den passenden Kaffee-Vollautomaten für dich und dein Team.</p>
					</div>
					<Scroll.Link className="sticky-teaser desktop" to={"kontakt"} smooth={true} offset={-200} duration={1000}>
						<span>zur Beratung</span>
					</Scroll.Link>
					<Scroll.Link className="sticky-teaser mobile" to={"kontakt"} smooth={true} offset={0} duration={1000}>
						<span>zur Beratung</span>
					</Scroll.Link>
					<div className="ViewStep2">
						<section className="machines-wrapper">
							{machines}
						</section>
					</div>
				</Scroll.Element>
			</MainTemplate>
		);
	}
}
export default ViewMachines;