import React from 'react';
import {Container} from 'react-bootstrap';

import Header from './Header';
import Footer from './Footer';


export default class MainTemplate extends React.Component {

	render() {
		return (
			<div className="MainTemplate">
				<main>
					{this.props.children}						
				</main>
			</div>
		);
	}
}
