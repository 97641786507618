import React from 'react';
import {Container, Row, Col, Collapse} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Helper from './Helper';
import testIMG from './img/portrait.jpg';
import Lightbox from "./Lightbox";

export default class CoffeeMaschine extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			showInfo: false
		}

		this.handleInfo = this.handleInfo.bind(this);
	}

	handleInfo(){
		this.setState({
			showInfo: !this.state.showInfo 
		});
	}

	render() {

		let class_selected = this.props.selected ? ' selected' : ' not-selected';
		let class_btn_selected = this.props.selected ? ' remove-selection' : ' select';
		let class_btn_continue = this.props.next_active ? ' active' : ' inactive';

		return (
			<div className={"CoffeeMaschine " + Helper.makeSafeForCSS(this.props.title)}>
				<div className="bg_wrapper">
					<Container>
						<Row>
							<Col xs={12} md={5} lg={6} className="col-maschine">
								<div className="img-selected-wrapper">
									<img src={this.props.img} alt={this.props.title} className="maschine img-fluid"/>
									<div className={"icon-selected" + class_selected}></div>
								</div>
							</Col>
							<Col xs={12} md={7} lg={6} className="col-content">
								<section className="content">
									<h2 className="title">{this.props.title}</h2>
									<div className="body">
										{this.props.body}
									</div>
									<div className="leasing">
										<span>Kaufen</span>
										<span>Mieten</span>
										<span>Leasen</span>
									</div>
									<div className="icon-wrapper">
										<div className={"icon-box icon-cups" + (this.props.cups ? '' : ' empty')}>
											<div className="icon"></div>
											{this.props.cups}
										</div>
										<div className={"icon-box icon-people"  + (this.props.people ? '' : ' empty')}>
											<div className="icon"></div>
											{this.props.people}
										</div>
										<div className={"icon-box icon-extra"  + (this.props.extra ? '' : ' empty')}>
											<div className="icon"></div>
											{this.props.extra}
										</div>
									</div>

									<div className="machine-buttons">
										<button onClick={this.handleInfo} className={"button button-info"} href="#0">
											<span>{this.state.showInfo ? 'schließen' : 'mehr Infos'}</span>
										</button>
										<div className={"button button-show-lightbox"}>
											<Lightbox img_src={this.props.lightbox}>
												<span>Maschine ansehen</span>
											</Lightbox>
										</div>
									</div>
								</section>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="bg_wrapper_info">
					<Container>
						<Collapse in={this.state.showInfo}>
							<div className="info">
								{this.props.info}
							</div>
						</Collapse>
					</Container>
				</div>
			</div>
		);
	}
}
