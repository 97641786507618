import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Obfuscate from "react-obfuscate";

import imgPortrait from './img/portrait_boeggemann.jpg';
import iconHaus from './img/icon_haus.svg';
import iconPhone from './img/icon_phone.svg';
import iconMail from './img/icon_brief.svg';
import iconMaps from './img/icon_maps.svg';
import iconParagraph from './img/icon_impressum.svg';
import iconPDF from './img/icon_pdf.svg';

import * as Scroll from 'react-scroll';

export default class ViewKontakt extends React.Component {

	render() {
		return (
			<Scroll.Element name={'kontakt'}>
				<section className="ViewKontakt">
					<Container>
						<div className="intro">
							<h2>Treffer.</h2>
							<p>
								Du hast den passenden Kaffee-Vollautomaten für dein Team gefunden? <br/>
								Super! Dann ruf mich an und wir besprechen, wie´s ganz einfach weitergeht. <br/>
								Nein? Ruf mich trotzdem an. Wir finden genau das, was du brauchst - versprochen!
							</p>
						</div>

						<Row>
							<Col xs={12} md={6}>
								<img src={imgPortrait} className={"img-portrait img-fluid"} alt="Philip Böggemann" />
							</Col>
							<Col xs={12} md={6}>
								<h3 className={"ansprechpartner"}>Philip Böggemann</h3>
								<p>Dein COMES - Ansprechpartner für <br/>
									Kaffee-Vollautomaten und alles, was dazu gehört.</p>
								<ul>
									<li>Beratung</li>
									<li>Service</li>
									<li>Bestellung</li>
								</ul>

								<a href="tel:02509993640" className="btn btn-call">
									Ruf an! <br/>
									02509 - 99364 0
								</a>

								<Obfuscate
									email={"p.boeggemann@comeskaffeekontor.de"}
									subject={""}
									className="btn btn-email"
									obfuscateChildren={false}
								>Schreib eine EMail <br/><span>p.boeggemann@comeskaffeekontor.de</span></Obfuscate>
							</Col>
						</Row>
					</Container>
					<div className="bg-grey"></div>
				</section>
			</Scroll.Element>
		);
	}
}
