export default class Helper{

	static removeFromArray(arr, element) {

		return arr.filter(el => { 
			return el != element
		});
	}

	static makeSafeForCSS(name) {
	    return '_' + name.replace(/[^a-z0-9]/g, function(s) {
	        var c = s.charCodeAt(0);
	        if (c == 32) return '-';
	        if (c >= 65 && c <= 90) return '' + s.toLowerCase();
	        return '';
	    });
	}	
}
