import React, { Component } from 'react';
import {Container} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';

class ViewHome extends Component {
	
	componentDidMount() {
	}

	render(){
	    return (
			<Scroll.Element name="home" className="ViewHome">
				<div className="hero-wrapper">
					<main>
						<Container>
							<div className="noHighlight">
								<section className="title">
									<h2>Der passende</h2>
									<h1>Kaffee-Vollautomat</h1>
									<h2>für dein Team.</h2>
								</section>
							</div>
							<div className="button-go">
								<Scroll.Link to="intro" smooth={true} duration={750}></Scroll.Link>
							</div>
						</Container>
					</main>
				</div>
				<div className="claim">
					<p>Das ist Comes: Maschinen, Service, + alle Kaffee Produkte aus einer Hand!</p>
				</div>
			</Scroll.Element>
	    );		
	}
}
export default ViewHome;