import React, {useState, useEffect, useRef} from "react";

const Lightbox = ({ children, img_src, alt, zIndex = 100 }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [current_img, setCurrentImg] = useState(0);
    const wrapper_ref = useRef(null);

    //reset current_img
    useEffect(() => { setCurrentImg(0) }, [isOpen]);


    const toggleIsOpen = (e) => {
        if(isOpen)
        {
            if(e.target === wrapper_ref.current)
            {
                setIsOpen(!isOpen);
            }
        } else {
            setIsOpen(!isOpen);
        }
    };

    const close = (e) => {
        setIsOpen(false);
    }

    const handleNextImg = () => {
        let max = img_src.length;
        let next_img = current_img + 1;
        if(next_img >= max){
            next_img = 0;
        }
        setCurrentImg(next_img);
    };

    const handlePrevImg = () => {
        let max = img_src.length;
        let next_img = current_img - 1;
        if(next_img < 0){
            next_img = max - 1;
        }
        setCurrentImg(next_img);
    }

    let images = [];
    let dots = [];
    let img_counter = 0;
    let class_visible;
    for (const img of img_src) {
        class_visible = (img_counter === current_img) ? 'visible' : 'hidden';
        images.push(<img src={img.src} alt={img.alt} key={img_counter} className={class_visible} />);
        dots.push(<div className={"dot " + class_visible} key={img_counter}></div>);
        img_counter++;
    }

    let caption = img_src[current_img].alt;

    return(
        <div className={'lightbox'} onClick={toggleIsOpen}>
            {children}
            {isOpen ?
                <div style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    height: '100vh',
                    width: '100vw',
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    cursor: 'pointer',
                    zIndex
                }} ref={wrapper_ref} className={'lightbox-wrapper'}>
                    <div className={'lightbox-container'}>
                        <div className="lightbox-images">
                            {images}
                        </div>
                        <div className={'lightbox-control'}>
                            <div className="lightbox-next" onClick={handleNextImg}></div>
                            <div className="lightbox-prev" onClick={handlePrevImg}></div>
                            <div className="lightbox-close" onClick={close}></div>
                            <div className="lightbox-caption">{caption}</div>
                            <div className="lightbox-dots">{dots}</div>
                        </div>
                    </div>
                </div>
                : null}
        </div>
    );
};

export default Lightbox;