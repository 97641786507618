import React, { Component } from 'react';

import './scss/App.scss';

import ViewHome from './ViewHome';
import CookieBanner from './CookieBanner';
import ViewMachines from "./ViewMachines";
import ViewKontakt from "./ViewKontakt";
import Header from "./Header";
import Footer from "./Footer";


class App extends Component {

    constructor(props)
    {
        super(props);

        this.state = {
            header_bg: false
        }

        this.handleScroll = this.handleScroll.bind(this);

        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll(e){

        if(window.scrollY > 600 && !this.state.header_bg){
            this.setState({
                header_bg: true
            });
        }
        else if(window.scrollY < 400 && this.state.header_bg){
            this.setState({
                header_bg: false
            });
        }
    }

    render(){
        return (
            <div className="site_wrapper">
                <Header withBG={this.state.header_bg} />
                <ViewHome />
                <ViewMachines />
                <ViewKontakt />
                <CookieBanner />
                <Footer />
            </div>
        );        
    }
}

export default App;