import React from 'react';
import cookie from 'react-cookies';

export default class CookieBanner extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			accepted: cookie.load('cookies_accepted')
		}

		this.acceptCookie = this.acceptCookie.bind(this);
	}

	acceptCookie(){

		var in_one_year = new Date();
		in_one_year.setDate(in_one_year.getDate()+365);

		cookie.save('cookies_accepted', true, {
			expires: in_one_year
		});

		this.setState({
			accepted: true 
		});
	}

	render() {

		console.log("Cookie accepted: " + this.state.accepted);

		let out = "";
		if(!this.state.accepted)
		{
			out = (
				<div className="CookieBanner">
					<p>Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren Rechten als Benutzer finden Sie in unserer <a href="https://www.comeskaffeekontor.de/#datenschutz" target="_blank">Datenschutzerklärung</a>. Klicken Sie auf „Ich stimme zu“, um Cookies zu akzeptieren und direkt unsere Website besuchen zu können.</p>
					<div className="btn_cookie_accept" onClick={this.acceptCookie}>Ich stimme zu</div>
				</div>
			);
		}
		
		return out;
	}
}
