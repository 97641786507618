import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default class Footer extends React.Component {

	render() {
		return (
			<footer className="Footer">
				<div className="separator"></div>
				<Container>
					<Row>
						<Col xs={12} lg={6}>
							<div className="col-wrapper">
								<span>Comes Kaffeekontor</span>   
								<span>48301 Nottuln</span>   
								<span>Beisenbusch 9-11</span>
							</div>
						</Col>
						<Col xs={12} lg={6}>
							<div className="col-wrapper">
								<span>Tel.: 02509 - 993 64 0</span>
								<span><a href="mailto:info@comeskaffeekontor.de">info@comeskaffeekontor.de</a></span>
								<span><a href="https://www.comeskaffeekontor.de/#kontakt">Impressum</a></span>
								<span><a href="https://www.comeskaffeekontor.de/#datenschutz">Datenschutz</a></span>
							</div>
						</Col>
					</Row>
				</Container>
			</footer>
		);
	}
}
